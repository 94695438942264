import { HiShieldCheck } from "react-icons/hi";
import { MdCancel ,MdAnalytics} from "react-icons/md";
const data = [
    {
        icon:<HiShieldCheck/>,
        heading : "Best interest rates on the market",
        detail:
        "Eercitation in fugiat est ut add ea cupidatat ut in cupidatat "
    },
    {
        icon:<MdCancel/>,
        heading : "Best interest rates on the market",
        detail:
        "Eercitation in fugiat est ut add ea cupidatat ut in cupidatat "
    },
    {
        icon:<MdAnalytics/>,
        heading : "Best interest rates on the market",
        detail:
        "Eercitation in fugiat est ut add ea cupidatat ut in cupidatat "
    }
];
export default data;